import { useMemo, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { useRecoilValue } from 'recoil';
import { serialize } from 'object-to-formdata';
import { setExtra, captureException } from '@sentry/browser';
import { useTranslation } from 'next-i18next';
import { useNotification } from 'hooks/useNotification';
import {
  SocietyProfileDefaultValuesType,
  StudentSocietyResponse,
  StudentSocietyAttributes,
  StudentSocietyRequest,
  SocietyDashboardAttributes,
  InviteAttendeesResponse,
  StudentSocietyRegistrationStats,
} from 'lib/models/student-society';
import { API_ROUTES } from 'lib/api-routes';
import { get, http, patch, post, remove, put } from 'lib/utils/http';
import { Option } from 'lib/models/option';

import { httpHeadersState } from 'lib/atoms/userSecretAtom';
import { parseArrayResponse, parseResponse } from 'lib/utils/parser';

import {
  UniversitiesResponse,
  UniversityAttributes,
} from 'lib/models/university';
import { TagAttributes, TagsResponse, TagType } from 'lib/models/tag';
import {
  HighlightAttributes,
  StudentSocietyHighlightRequest,
} from 'lib/models/highlight';
import {
  FlagshipEventAttributes,
  FlagshipEventRequest,
  FlagshipEventResponse,
} from 'lib/models/flagship-event';
import { CommitteeAttributes } from 'lib/models/committee';
import { StatsAttributes, StatsRequest } from 'lib/models/stats';
import {
  CustomLinksRequest,
  CustomLinksAttributes,
} from 'lib/models/custom-links';
import { Notice } from 'lib/models/notice';
import { useAuth } from 'lib/providers/AuthProvider';
import {
  OpportunitiesResponse,
  OpportunityAttributes,
} from 'lib/models/opportunity';
import {
  StudentSocietyRegistrationAttributes,
  StudentSocietyRegistrationResponse,
} from 'lib/models/student-society-registration';
import { LinkTreeAttributesType } from 'lib/models/linkTree';
import { apiInstance } from 'lib/utils/axios';

export interface SocietyProfileReturnType {
  societyProfile: StudentSocietyAttributes;
  slug: string;
  id: string;
  universities: Array<Option>;
  societyUniversity: UniversityAttributes;
  topics: Array<Option>;
  societyTopics: Array<TagAttributes<TagType.Topic>> | Array<Option>;
  offerings: Array<Option>;
  societyOfferings: Array<TagAttributes<TagType.Offering>> | Array<Option>;
  societyHighlights: Array<HighlightAttributes>;
  highlights: Array<Option>;
  societyFlagshipEvents: Array<FlagshipEventAttributes>;
  societyTeamMembers: Array<CommitteeAttributes>;
  societyStats: Array<StatsAttributes>;
  addHighlight: (
    highlight: StudentSocietyHighlightRequest
  ) => Promise<Notice | undefined>;
  removeHighlight: (id: string) => Promise<Notice | undefined>;
  onSocietyProfileUpdate: (
    student_society: StudentSocietyRequest,
    throwError?: boolean,
    hideNotification?: boolean
  ) => Promise<StudentSocietyResponse | undefined>;
  topicActionHandler: (
    topic_ids: string[]
  ) => Promise<StudentSocietyResponse | undefined>;
  offeringActionHandler: (
    offering_ids: string[]
  ) => Promise<StudentSocietyResponse | undefined>;
  onAddFlagshipEvent: (
    flagship_event: FlagshipEventRequest
  ) => Promise<FlagshipEventResponse | undefined>;
  onRemoveFlagshipEvent: (id: string) => Promise<Notice | undefined>;
  onMakeTopicPrimary: (
    topicId: string
  ) => Promise<StudentSocietyResponse | undefined>;
  primaryTopic: TagAttributes<TagType.Topic> | Option;
  addStats: (stats: StatsRequest) => Promise<Notice | undefined>;
  removeStats: (id: string) => Promise<Notice | undefined>;
  onRemoveBanner: (id: string) => Promise<unknown>;
  societyCustomLinks: Array<CustomLinksAttributes>;
  onRemoveCustomLink: (id: string) => Promise<Notice | undefined>;
  onUpdateCustomLink: (
    data: CustomLinksRequest,
    id: string
  ) => Promise<Notice | undefined>;
  addCustomLinks: (data: CustomLinksRequest) => Promise<Notice | undefined>;
  onUpdateSocietyProfile: (
    data: SocietyProfileDefaultValuesType
  ) => Promise<StudentSocietyResponse | undefined>;
  isLoading?: boolean | undefined;
  similarEvents: Array<OpportunityAttributes>;
  societyDashboard: SocietyDashboardAttributes | undefined;
  mutateSocietyDashboard: () => void;
  isSocietyDashboardLoading: boolean;
  addNewSubscriber: (payload: {
    first_name: string;
    last_name: string;
    email: string;
  }) => Promise<void>;
  inviteAttendees: () => Promise<void>;
  inviteProfileViewers: () => Promise<void>;
  inviteEventViewers: () => Promise<void>;
  sendFillDataEmail: () => Promise<void>;
  subscribersData: StudentSocietyRegistrationAttributes[];
  societyRegistrationStats: StudentSocietyRegistrationStats | undefined;
  subscribersListLoading: boolean;
  fetchLinkTreeLinks: (
    linkTreeUrl: string
  ) => Promise<LinkTreeAttributesType[] | undefined>;
  updateCustomLinks: (
    data: CustomLinksAttributes[],
    throwError?: boolean
  ) => Promise<void>;
  updateSubscriberApproval: (
    subscribers_require_approval: boolean
  ) => Promise<StudentSocietyResponse | undefined>;
  updateInviteBannerStatus: (
    invite_later_banner_hidden: boolean
  ) => Promise<StudentSocietyResponse | undefined>;
  updateManagerInfo: ({
    partialManagerAttribute,
  }: {
    partialManagerAttribute: Partial<StudentSocietyAttributes>;
  }) => Promise<StudentSocietyResponse | undefined>;
}

export const useSocietyProfile = (): SocietyProfileReturnType => {
  const notificationInstance = useNotification();
  const { headers, plainHeaders } = useRecoilValue(httpHeadersState);
  const { isManager, updatePartialManager } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const societyProfilePath = `${API_ROUTES.STUDENT_SOCIETY}`;

  const [t] = useTranslation('community');

  const { data: societyProfileResponse } = useSWR<StudentSocietyResponse>(
    isManager ? [societyProfilePath, headers] : null,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const societyProfile = useMemo(() => {
    return parseResponse<StudentSocietyAttributes>(societyProfileResponse);
  }, [societyProfileResponse]);

  const { slug, id, short_slug } = societyProfile || {};

  //universities
  const universitiesPathname = `${API_ROUTES.UNIVERSITIES}`;
  const { data: universitiesResponse } = useSWR<UniversitiesResponse>(
    [universitiesPathname, headers],
    get,
    { revalidateOnFocus: false }
  );
  const universitiesList = useMemo(() => {
    return parseArrayResponse<UniversityAttributes>(universitiesResponse);
  }, [universitiesResponse]);
  const universities = universitiesList.map((university) => {
    return { id: university.id, label: university.name, value: university.id };
  });
  const societyUniversity = useMemo(() => {
    return parseResponse<UniversityAttributes>(societyProfile?.university);
  }, [societyProfile?.university]);

  //topics
  const topicsPathname = `${API_ROUTES.TOPICS}`;

  const { data: topicsResponse } = useSWR<TagsResponse<TagType.Topic>>(
    [topicsPathname, headers],
    get,
    { revalidateOnFocus: false }
  );
  const societyTopics = useMemo(() => {
    return parseArrayResponse<TagAttributes<TagType.Topic>>(
      societyProfile?.topics
    );
  }, [societyProfile?.topics]);

  const primaryTopic = useMemo(() => {
    return parseResponse<TagAttributes<TagType.Topic>>(
      societyProfile?.primary_topic
    );
  }, [societyProfile?.primary_topic]);

  const topics = useMemo(() => {
    const topics =
      parseArrayResponse<TagAttributes<TagType.Topic>>(topicsResponse);
    return topics.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }));
  }, [topicsResponse, societyTopics]);

  const formatFormData = (formData: SocietyProfileDefaultValuesType) => {
    const {
      topic_ids: topics,
      offering_ids: offerings,
      student_societies_highlights_attributes: highlights,
      stats_attributes: stats,
      flagship_events_attributes: flagshipEvents,
      custom_links_attributes: customLinks,
      logo,
      banner,
      ...data
    } = formData;
    const icon =
      logo && typeof logo !== 'string'
        ? ((logo as FileList)?.length > 0 && logo[0]) || []
        : logo;
    const bannerFile =
      banner && typeof banner !== 'string'
        ? (banner && (banner as FileList)?.length > 0 && banner[0]) || []
        : banner;
    const primaryTopic = (topics as { id: string; isPrimary: boolean }[])?.find(
      (top) => top?.isPrimary
    );
    const topic_ids = (topics as { id: string; isPrimary: boolean }[])
      ?.filter((top: { isPrimary: boolean }) => !top?.isPrimary)
      ?.map((top: { id: string }) => top?.id);
    const offering_ids = offerings?.map((offering) => offering?.id);
    const deletedHighlights = societyHighlights
      ?.filter(
        (high) => !highlights?.some((highItem) => high?.id === highItem?.id)
      )
      ?.map((highlight) => ({ ...highlight, _destroy: true }));
    const student_societies_highlights_attributes = [
      ...(highlights && Array.isArray(highlights) ? highlights : []),
      ...(Array.isArray(deletedHighlights) ? deletedHighlights : []),
    ]?.map(({ id, name, emoji, _destroy }) => ({
      name,
      emoji,
      ...(id ? { id } : {}),
      ...(_destroy ? { _destroy } : {}),
    }));
    const deletedStats = societyStats
      ?.filter((stat) => !stats.some((statItem) => stat?.id === statItem?.id))
      ?.map((event) => ({ ...event, _destroy: true }));

    const stats_attributes = [
      ...(stats ? stats : []),
      ...(Array.isArray(deletedStats) ? deletedStats : []),
    ]?.map(({ id, value, title, emoji, _destroy }) => ({
      value,
      title,
      emoji,
      ...(id ? { id } : {}),
      ...(_destroy ? { _destroy } : {}),
    }));
    const deletedEvents = societyFlagshipEvents
      ?.filter(
        (event) =>
          !flagshipEvents.some((flagEvent) => event?.id === flagEvent?.id)
      )
      ?.map((event) => ({ ...event, _destroy: true }));
    const flagship_events_attributes = [
      ...(flagshipEvents ? flagshipEvents : []),
      ...(Array.isArray(deletedEvents) ? deletedEvents : []),
    ]?.map(({ id, title, icon, icon_url, url, _destroy }) => ({
      title,
      icon,
      url,
      ...(!icon && icon_url ? { icon_link: icon_url } : {}),
      ...(id ? { id } : {}),
      ...(_destroy ? { _destroy } : {}),
    }));
    const deletedCustomLinks = societyCustomLinks
      ?.filter((link) => !customLinks.some((cust) => link?.id === cust?.id))
      ?.map((item) => ({ ...item, _destroy: true }));

    const custom_links_attributes = [
      ...(customLinks ? customLinks : []),
      ...(Array.isArray(deletedCustomLinks) ? deletedCustomLinks : []),
    ].map(({ id, position, title, emoji, link, _destroy }) => ({
      title,
      emoji,
      link,
      ...(id ? { id } : {}),
      ...(position ? { position } : {}),
      ...(_destroy ? { _destroy } : {}),
    }));

    return {
      topic_ids,
      offering_ids,
      student_societies_highlights_attributes,
      stats_attributes,
      flagship_events_attributes,
      custom_links_attributes,
      primary_topic_id:
        primaryTopic && primaryTopic?.id ? primaryTopic.id : null,
      ...(icon !== societyProfile?.icon_url ? { icon } : {}),
      ...(bannerFile && bannerFile !== societyProfile?.banner_url
        ? { banner: bannerFile }
        : {}),
      ...(!bannerFile ? { remove_banner: true } : {}),
      ...data,
    };
  };

  const onUpdateSocietyProfile = async (
    data: SocietyProfileDefaultValuesType
  ): Promise<StudentSocietyResponse | undefined> => {
    const formattedData = formatFormData(data);

    const { offering_ids, topic_ids, ...rest } = formattedData;

    const formDataWithOutIndices = serialize(
      {
        student_society: {
          offering_ids,
          topic_ids,
        },
      },
      { allowEmptyArrays: true, indices: false }
    );

    const formData = serialize(
      {
        student_society: rest,
      },
      { allowEmptyArrays: true, indices: true },
      formDataWithOutIndices
    );

    try {
      setIsLoading(true);
      const response = await http<StudentSocietyResponse>(
        `${API_ROUTES.STUDENT_SOCIETY}`,
        {
          method: 'PATCH',
          body: formData,
          headers: plainHeaders,
        }
      );
      await mutate([API_ROUTES.STUDENT_SOCIETY, headers], response, false);

      try {
        // fetch webpage on save changes
        // so that updated page is present
        // when view profile is clicked
        const domain =
          typeof window !== 'undefined'
            ? window.location.origin
            : process.env.NEXT_PUBLIC_SITE_URL;
        const publicPagePathname = `${domain}/societies/${slug}`;
        const publicPageShortSlug = `${domain}/s/${short_slug}`;
        fetch(publicPagePathname);
        fetch(publicPageShortSlug);
      } catch {
        console.error('Error in fetching public page');
      }
      notificationInstance.success({
        title: 'Success!',
        message: 'Society updated successfully.',
      });
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      setExtra('formatted Data', formattedData);
      setExtra('form Data', formData);
      captureException(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  //add/remove topic handler
  const topicActionHandler = async (
    topic_ids: string[]
  ): Promise<StudentSocietyResponse | undefined> => {
    const formData = serialize(
      {
        student_society: { topic_ids: [...topic_ids] },
      },
      { allowEmptyArrays: true }
    );
    try {
      const response = await http<StudentSocietyResponse>(
        `${API_ROUTES.STUDENT_SOCIETY}`,
        {
          method: 'PATCH',
          body: formData,
          headers: plainHeaders,
        }
      );
      mutate([API_ROUTES.STUDENT_SOCIETY, headers], response, false);
      notificationInstance.success({
        title: 'Success!',
        message: 'Topics updated successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  //offerings
  const offeringsPathname = `${API_ROUTES.OFFERINGS}`;
  const { data: offeringsResponse } = useSWR<TagsResponse<TagType.Offering>>(
    [offeringsPathname, headers],
    get,
    { revalidateOnFocus: false }
  );

  const societyOfferings = useMemo(() => {
    return parseArrayResponse<TagAttributes<TagType.Offering>>(
      societyProfile?.offerings
    );
  }, [societyProfile?.offerings]);

  const offerings = useMemo(() => {
    const offerings =
      parseArrayResponse<TagAttributes<TagType.Offering>>(offeringsResponse);

    return offerings.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }));
  }, [offeringsResponse, societyOfferings]);

  //add/remove offerings handler
  const offeringActionHandler = async (
    offering_ids: string[]
  ): Promise<StudentSocietyResponse | undefined> => {
    const body = {
      student_society: { offering_ids: [...offering_ids] },
    };
    const formData = serialize(body, { allowEmptyArrays: true });

    try {
      const response = await http<StudentSocietyResponse>(
        `${API_ROUTES.STUDENT_SOCIETY}`,
        {
          method: 'PATCH',
          body: formData,
          headers: plainHeaders,
        }
      );
      mutate([API_ROUTES.STUDENT_SOCIETY, headers], response, false);
      notificationInstance.success({
        title: 'Success!',
        message: 'Offerings updated successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
      setExtra('offeringActionHandler body', JSON.stringify(body));
      captureException(error);
    }
  };

  const updateManagerInfo = async ({
    partialManagerAttribute,
  }: {
    partialManagerAttribute: Partial<StudentSocietyAttributes>;
  }): Promise<StudentSocietyResponse | undefined> => {
    const body = {
      student_society: { ...partialManagerAttribute },
    };
    try {
      const { data: response } =
        await apiInstance.patch<StudentSocietyResponse>(
          API_ROUTES.STUDENT_SOCIETY,
          body
        );
      await updatePartialManager({ student_society: response });
      return response;
    } catch (error) {
      console.error(error);
      notificationInstance.handleExceptionError(error);
    }
  };

  const societyHighlights = useMemo(() => {
    return parseArrayResponse<HighlightAttributes>(
      societyProfile?.student_societies_highlights
    );
  }, [societyProfile]);

  const societyCustomLinks = useMemo(() => {
    return parseArrayResponse<CustomLinksAttributes>(
      societyProfile?.custom_links
    );
  }, [societyProfile]);

  const highlights = useMemo(() => {
    const remainingList = societyHighlights.filter((highlight) => {
      return (
        societyHighlights.findIndex(
          (societyHighlight) => societyHighlight.highlight_id === highlight.id
        ) < 0
      );
    });
    return remainingList.map(({ id, name }) => ({
      id,
      value: id,
      label: name,
    }));
  }, [societyHighlights]);

  //add highlight handler
  const addHighlight = async (
    highlight: StudentSocietyHighlightRequest
  ): Promise<Notice | undefined> => {
    const body = { student_societies_highlight: highlight };
    try {
      const response = await post<Notice>(
        API_ROUTES.SOCIETY_HIGHLIGHTS,
        body,
        headers
      );
      mutate([API_ROUTES.SOCIETY_HIGHLIGHTS, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'Highlight added successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  //remove highlight handler
  const removeHighlight = async (id: string): Promise<Notice | undefined> => {
    const pathname = `${API_ROUTES.SOCIETY_HIGHLIGHTS}/${id}`;
    try {
      const response = await remove<Notice>(pathname, headers);
      mutate([API_ROUTES.SOCIETY_HIGHLIGHTS, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'Highlight removed successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  //flasgship events
  const societyFlagshipEvents = useMemo(() => {
    return parseArrayResponse<FlagshipEventAttributes>(
      societyProfile?.flagship_events
    );
  }, [societyProfile?.flagship_events]);

  //on add flagship event handler
  const onAddFlagshipEvent = async (
    data: FlagshipEventRequest
  ): Promise<FlagshipEventResponse | undefined> => {
    const { icon, defaultImage, url, title } = data;
    const imageurl = `${process.env.NEXT_PUBLIC_S3_BUCKET_URL}/images/${defaultImage}`;
    const avatarIcon = icon as FileList;
    const payload = {
      url,
      title,
      ...(!avatarIcon.length && defaultImage ? { icon_link: imageurl } : {}),
      ...(icon && avatarIcon.length ? { icon: avatarIcon[0] } : {}),
    };
    const formData = serialize({
      flagship_event: payload,
    });
    try {
      const response = await http<FlagshipEventResponse>(
        `${API_ROUTES.FLAGSHIP_EVENTS}`,
        {
          method: 'POST',
          body: formData,
          headers: plainHeaders,
        }
      );
      mutate([API_ROUTES.STUDENT_SOCIETY, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'Flagship event added successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  //on remove flagship event handler
  const onRemoveFlagshipEvent = async (
    id: string
  ): Promise<Notice | undefined> => {
    const pathname = `${API_ROUTES.FLAGSHIP_EVENTS}/${id}`;
    try {
      const response = await remove<Notice>(pathname, headers);
      mutate([API_ROUTES.STUDENT_SOCIETY, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'Flagship event removed successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  //team  members
  const societyTeamMembers = useMemo(() => {
    return parseArrayResponse<CommitteeAttributes>(societyProfile?.managers);
  }, [societyProfile?.managers]);

  //society profile update handler
  const onSocietyProfileUpdate = async (
    student_society: StudentSocietyRequest,
    throwError?: boolean,
    hideNotification?: boolean
  ): Promise<StudentSocietyResponse | undefined> => {
    const { logo, banner, ...rest } = student_society;
    const icon = (logo && logo?.length > 0 && logo[0]) || [];
    const bannerFile = (banner && banner?.length > 0 && banner[0]) || [];
    const body = { student_society: { ...rest, icon, banner: bannerFile } };
    const formData = serialize(body, { indices: true });
    try {
      const response = await http<StudentSocietyResponse>(
        `${API_ROUTES.STUDENT_SOCIETY}`,
        {
          method: 'PATCH',
          body: formData,
          headers: plainHeaders,
        }
      );
      mutate([API_ROUTES.STUDENT_SOCIETY, headers], response, false);
      if (!hideNotification) {
        notificationInstance.success({
          title: 'Success!',
          message: 'Society profile updated successfully.',
        });
      }

      return response;
    } catch (error) {
      if (throwError) {
        throw error;
      } else {
        notificationInstance.handleExceptionError(error);
      }
    }
  };

  const onMakeTopicPrimary = async (
    topicId: string
  ): Promise<StudentSocietyResponse | undefined> => {
    try {
      const body = { primary_topic_id: topicId };
      const path = `${API_ROUTES.STUDENT_SOCIETY}`;
      const response = await patch<StudentSocietyResponse>(path, body, headers);
      mutate([API_ROUTES.STUDENT_SOCIETY, headers], response, false);
      notificationInstance.success({
        title: 'Success!',
        message: 'Primary topic updated successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  //stats
  const societyStats = useMemo(() => {
    return parseArrayResponse<StatsAttributes>(societyProfile?.stats);
  }, [societyProfile?.stats]);

  //add stats handler
  const addStats = async (stat: StatsRequest): Promise<Notice | undefined> => {
    const body = stat;
    try {
      const response = await post<Notice>(
        API_ROUTES.SOCIETY_STATS,
        body,
        headers
      );
      mutate([API_ROUTES.STUDENT_SOCIETY, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'Stats added successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  //add custom links
  const addCustomLinks = async (
    data: CustomLinksRequest
  ): Promise<Notice | undefined> => {
    const body = data;
    try {
      const response = await post<Notice>(
        API_ROUTES.SOCIETY_CUSTOM_LINKS,
        body,
        headers
      );
      mutate([API_ROUTES.SOCIETY_CUSTOM_LINKS, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'Custom Link added successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const onUpdateCustomLink = async (
    stat: CustomLinksRequest,
    id: string
  ): Promise<Notice | undefined> => {
    const body = stat;
    try {
      const response = await put<Notice>(
        `${API_ROUTES.SOCIETY_CUSTOM_LINKS}/${id}`,
        body,
        headers
      );
      mutate([API_ROUTES.SOCIETY_CUSTOM_LINKS, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'Custom Link updated successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const onRemoveCustomLink = async (
    id: string
  ): Promise<Notice | undefined> => {
    try {
      const response = await remove<Notice>(
        `${API_ROUTES.SOCIETY_CUSTOM_LINKS}/${id}`,
        headers
      );
      mutate([API_ROUTES.SOCIETY_CUSTOM_LINKS, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'Custom Link deleted successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  //remove stats handler
  const removeStats = async (id: string): Promise<Notice | undefined> => {
    const pathname = `${API_ROUTES.SOCIETY_STATS}/${id}`;
    try {
      const response = await remove<Notice>(pathname, headers);
      mutate([API_ROUTES.STUDENT_SOCIETY, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'Stats removed successfully.',
      });
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const onRemoveBanner = async (id: string): Promise<unknown> => {
    const pathname = `${API_ROUTES.STUDENT_SOCIETIES}/${id}/remove_banner`;
    try {
      const response = await remove(pathname, headers);
      mutate([API_ROUTES.STUDENT_SOCIETY, headers]);
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    }
  };

  const {
    data: societyDashboard,
    mutate: mutateSocietyDashboard,
    isLoading: isSocietyDashboardLoading,
  } = useSWR<SocietyDashboardAttributes>(
    isManager ? [API_ROUTES.SOCIETY_DASHBOARD, headers] : null,
    get,
    {
      revalidateOnFocus: false,
    }
  );

  const { total_count } = societyDashboard || {};

  const similarEventsPathname = `${API_ROUTES.SOCIETY_TOUCHPOINTS}/similar?with_details=true&page=1&per_page=10&touchpointable_types[]=Event`;

  const { data: similarEventsResponse } = useSWR<OpportunitiesResponse>(
    isManager && total_count === 0 ? [similarEventsPathname, headers] : null,
    get,
    {
      revalidateOnFocus: false,
    }
  );
  const similarEvents = parseArrayResponse<OpportunityAttributes>(
    similarEventsResponse
  );

  const addNewSubscriber = async (payload: {
    first_name: string;
    last_name: string;
    email: string;
  }) => {
    const body = {
      student_society_registration: payload,
    };

    try {
      await post<StudentSocietyRegistrationResponse>(
        `${API_ROUTES.ADD_SOCIETY_SUBSCRIBERS}`,
        body,
        headers
      );
      await mutateSubscribersData();
    } catch (error) {
      notificationInstance.handleExceptionError(error);
      throw error;
    }
  };

  const inviteAttendees = async () => {
    try {
      await post<InviteAttendeesResponse>(
        `${API_ROUTES.INVITE_ATTENDEES}`,
        {},
        headers
      );
    } catch (error) {
      notificationInstance.handleExceptionError(error);
      throw error;
    }
  };

  const inviteProfileViewers = async () => {
    try {
      await post<InviteAttendeesResponse>(
        `${API_ROUTES.INVITE_PROFILE_VIEWERS}`,
        {},
        headers
      );
    } catch (error) {
      notificationInstance.handleExceptionError(error);
      throw error;
    }
  };

  const inviteEventViewers = async () => {
    try {
      await post<InviteAttendeesResponse>(
        `${API_ROUTES.INVITE_EVENT_VIEWERS}`,
        {},
        headers
      );
    } catch (error) {
      notificationInstance.handleExceptionError(error);
      throw error;
    }
  };
  const sendFillDataEmail = async () => {
    try {
      await apiInstance.post(`${API_ROUTES.SEND_FILL_DATA_EMAIL}`);
      notificationInstance.acknowledge({
        message: 'Invitation sent',
        timeout: 5000,
      });
    } catch (error) {
      notificationInstance.handleExceptionError(error);
      throw error;
    }
  };

  const {
    data: subscribersListResponse,
    isLoading: subscribersListLoading,
    mutate: mutateSubscribersData,
  } = useSWR<StudentSocietyRegistrationResponse>(
    [API_ROUTES.STUDENT_SOCIETY_REGISTRATION, headers],
    get
  );

  const subscribersData = useMemo(
    () =>
      parseArrayResponse<StudentSocietyRegistrationAttributes>(
        subscribersListResponse
      ),
    [subscribersListResponse]
  );
  const { data: societyRegistrationStats } =
    useSWR<StudentSocietyRegistrationStats>(
      [API_ROUTES.STUDENT_SOCIETY_REGISTRATION_STATS, headers],
      get
    );

  const fetchLinkTreeLinks = async (url: string) => {
    setIsLoading(true);
    try {
      return await post<LinkTreeAttributesType[]>(
        API_ROUTES.FETCH_LINK_TREE,
        {
          linktree_url: url,
        },
        headers
      );
    } catch (error) {
      notificationInstance.handleExceptionError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateCustomLinks = async (
    data: CustomLinksAttributes[],
    throwError?: boolean
  ) => {
    setIsLoading(true);
    const body = {
      student_society: {
        custom_links_attributes: data,
      },
    };
    try {
      const response = await patch<StudentSocietyResponse>(
        API_ROUTES.STUDENT_SOCIETY,
        body,
        headers
      );
      await mutate([API_ROUTES.SOCIETY_DASHBOARD, headers]);
      notificationInstance.success({
        title: 'Success!',
        message: 'External Links added successfully.',
      });
      await mutate([API_ROUTES.STUDENT_SOCIETY, headers], response, false);
      return;
    } catch (error) {
      if (throwError) {
        throw error;
      } else {
        notificationInstance.handleExceptionError(error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateSubscriberApproval = async (
    subscribers_require_approval: boolean
  ): Promise<StudentSocietyResponse | undefined> => {
    const body = {
      student_society: { subscribers_require_approval },
    };
    const formData = serialize(body, { allowEmptyArrays: true });

    try {
      const response = await http<StudentSocietyResponse>(
        `${API_ROUTES.STUDENT_SOCIETY}`,
        {
          method: 'PATCH',
          body: formData,
          headers: plainHeaders,
        }
      );
      if (response) {
        notificationInstance.acknowledge({
          message: `${t('require-approval')} ${
            subscribers_require_approval ? t('enabled') : t('disabled')
          }`,
          timeout: 5000,
        });
      }
      mutate([API_ROUTES.STUDENT_SOCIETY, headers], response, false);
      return response;
    } catch (error) {
      notificationInstance.handleExceptionError(error);
      captureException(error);
    }
  };

  const updateInviteBannerStatus = async (
    invite_later_banner_hidden: boolean
  ): Promise<StudentSocietyResponse | undefined> => {
    const body = {
      student_society: { invite_later_banner_hidden },
    };
    const formData = serialize(body, { allowEmptyArrays: true });

    try {
      const response = await http<StudentSocietyResponse>(
        `${API_ROUTES.STUDENT_SOCIETY}`,
        {
          method: 'PATCH',
          body: formData,
          headers: plainHeaders,
        }
      );
      mutate([API_ROUTES.STUDENT_SOCIETY, headers], response, false);
      return response;
    } catch (error) {
      captureException(error);
    }
  };

  return {
    societyProfile,
    slug,
    id,
    universities,
    societyUniversity,
    topics,
    societyTopics,
    primaryTopic,
    offerings,
    societyOfferings,
    societyHighlights,
    highlights,
    societyFlagshipEvents,
    societyTeamMembers,
    societyStats,
    addHighlight,
    removeHighlight,
    onSocietyProfileUpdate,
    topicActionHandler,
    offeringActionHandler,
    onAddFlagshipEvent,
    onRemoveFlagshipEvent,
    onMakeTopicPrimary,
    addStats,
    removeStats,
    onRemoveBanner,
    societyCustomLinks,
    addCustomLinks,
    onUpdateCustomLink,
    onRemoveCustomLink,
    onUpdateSocietyProfile,
    isLoading,
    similarEvents,
    societyDashboard,
    mutateSocietyDashboard,
    isSocietyDashboardLoading,
    addNewSubscriber,
    subscribersData,
    subscribersListLoading,
    fetchLinkTreeLinks,
    updateCustomLinks,
    inviteAttendees,
    inviteProfileViewers,
    inviteEventViewers,
    societyRegistrationStats,
    updateSubscriberApproval,
    updateInviteBannerStatus,
    sendFillDataEmail,
    updateManagerInfo,
  };
};
